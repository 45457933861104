<template>
    <div class="inner_pages" id="downgrade_index">
        <!-- <inner-sidebar /> -->
        <div class="respective_content" v-if="allAssetsLoader">
            <quote-loader />
        </div>
        <div class="respective_content" v-else>
            <div class="downgrader">
                <div class="info_bar">
                    <div class="step_bar2">
                        <ul>
                            <li :class="{ active : step == 0 || step > 0 }">
                                <span @click="handleNextStep(0)">1</span>
                                <h6>Contacts</h6>
                            </li>
                            <li :class="{ active : step == 1 || step > 1 }">
                                <span @click="handleNextStep(1)">2</span>
                                <h6>Assets</h6>
                            </li>
                            <li :class="{ active : step == 2 || step > 2 }">
                                <span @click="handleNextStep(2)">3</span>
                                <h6>Files</h6>
                            </li>
                            <li :class="{ active : step == 3 || step > 3 }">
                                <span @click="handleNextStep(3)">4</span>
                                <h6>Terms</h6>
                            </li>
                            <li :class="{ active : step == 4 || step > 4 }">
                                <span @click="handleNextStep(4)">5</span>
                                <h6>Finish</h6>
                            </li>
                        </ul>
                        <div class="total_bar"><span :style="`width:${(100 / 4) * step }%`"></span></div>
                    </div>
                </div>
                <div class="step_content" v-if="step == 0">
                    <div class="details_info">
                        <p v-if="extraConsumption.contacts > 0">
                            You currently have <span>{{ consumption.contacts }}</span> of the <span>{{ currentPlanLimit.contacts }}</span> contacts available on your <span>{{ currentPlan }}</span> plan<br/><br/>
                            To downgrade to <span>{{ newPlan }}</span> plan you will need to archive a minimum of <span>{{ consumption.contacts > newPlanLimit.contacts ? consumption.contacts - newPlanLimit.contacts : 0 }}</span> contacts.<br/><br/>
                            You can do this by navigating to your contact center and using bulk action to archive inactive contacts in batches of 500 or fewer.<br/><br/>
                            Once a contact is deleted they lose immedietly client portal access, are unable to receive SMS or emails, and will no longer be visible in your contact center<br/><br/>
                            They are essentially soft deleted from your account.<br/><br/>
                            The only way to edit, communicate, or reccover these contact is to upgrade to <span>{{ currentPlan }}</span> plan or higher.<br/><br/>
                            After any contact is deleted for period exceeding 90 days Thrive Systems, Inc reserves the right to delete any deleted data to free up server space.<br/><br/>
                            As soon as your current contact count is less than your plan limit you will be able to proceed with your account downgrade.<br/><br/>
                            <router-link to="/">Click here</router-link>
                            to go to your contact center.
                        </p>
                        <p v-else>You are not over your contact limit. Click next to proceed.</p>
                    </div>
                </div>
                <div class="step_content" v-if="step == 1">
                    <div class="details_info">
                        <p>
                            Below is a list of assets that must be deleted to downgrade your account. To delete an asset select the feature from the dropdown menu in the first column and slide the correct asset toggles to delete.<br /><br />
                            Once the correct amount of assets has been selected from the first column the second column will display all green checkmarks.<br /><br />
                            After all features in the middle column are green the downgrade button will become clickable.
                        </p>
                        <ul class="plan_details">
                            <li>
                                <h5>Current Plan:</h5>
                                <div class="plan capitalize">{{ currentPlan }} ${{ plans.old ? plans.old.amount : 0 }}/{{ plans.old ? plans.old.interval : 'month' }}</div>
                            </li>
                            <li>
                                <h5>New Plan:</h5>
                                <div class="plan capitalize">{{ newPlan }} ${{ plans.new ? plans.new.amount : 0 }}/{{ plans.new ? plans.new.interval : 'month' }}</div>
                            </li>
                        </ul>
                        <div class="assets_wpr">
                            <div class="asset_box">
                                <h4>Assets To Be Deleted</h4>
                                <ul class="asset_list">
                                    <li v-for="(asset, i) of assets" :key="i">
                                        <div class="list_item capitalize" @click="toggleProduct(asset, overdueAssets[asset])" :class="overdueAssets[asset] ? 'items' : ''">
                                            {{ asset }}
                                            <span v-if="overdueAssets[asset]">{{ overdueAssets[asset] }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="asset_box">
                                <h4> {{ overdueAssets ? overdueCount - archivedCount : 0 }} Assets Over Limit <span class="success" v-if="archivedCount >= overdueCount"><i class="fas fa-check"></i></span></h4>
                                <ul class="asset_list">
                                    <li v-for="(asset, i) in assets" :key="i">
                                        <div class="list_item capitalize">
                                            {{ asset }}
                                            <span>
                                                <i :class="`fas ${overdueAssets[asset] > archivedAssets[asset] ? 'fa-times-circle danger' : 'fa-check-circle sucess'}`"></i>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="asset_box">
                                <h4>{{ Object.values(archivedAssets).reduce((a, b) => a + b, 0) }} Assets Will Be Deleted</h4>
                                <ul class="archived_list" v-if="assetsInit">
                                    <li class="archive-title" v-if="archivedAssets.playbooks"><div class="sm_switch">Playbooks</div></li>
                                    <template v-for="(playbook, p) of form.playbooks" :key="p">
                                        <li v-if="playbook.archived">
                                            <div class="sm_switch">
                                                <label :for="`playbook-${p}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ playbook.name }}</h5>
                                                    <input type="checkbox" :id="`playbook-${p}`" :true-value="1" :false-value="0" v-model="playbook.archived" hidden>
                                                    <div><span :class="{on: playbook.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li> 
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.pages"><div class="sm_switch">Pages</div></li>
                                    <template v-for="(page, p) of form.pages" :key="p">
                                        <li v-if="page.archived">
                                            <div class="sm_switch">
                                                <label :for="`page-${p}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ page.name }}</h5>
                                                    <input type="checkbox" :id="`page-${p}`" :true-value="1" :false-value="0" v-model="page.archived" hidden>
                                                    <div><span :class="{on: page.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.forms"><div class="sm_switch">Forms</div></li>
                                    <template v-for="(survey, f) of form.forms" :key="f">
                                        <li v-if="survey.archived">
                                            <div class="sm_switch">
                                                <label :for="`form-${f}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ survey.name }}</h5>
                                                    <input type="checkbox" :id="`form-${f}`" :true-value="1" :false-value="0" v-model="survey.archived" hidden>
                                                    <div><span :class="{on: survey.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.habits"><div class="sm_switch">Habits</div></li>
                                    <template v-for="(habit, h) of form.habits" :key="h">
                                        <li v-if="habit.archived">
                                            <div class="sm_switch">
                                                <label :for="`habit-${h}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ habit.name }}</h5>
                                                    <input type="checkbox" :id="`habit-${h}`" :true-value="1" :false-value="0" v-model="habit.archived" hidden>
                                                    <div><span :class="{on: habit.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.progress"><div class="sm_switch">Progress</div></li>
                                    <template v-for="(progress, p) of form.progress" :key="p">
                                        <li v-if="progress.archived">
                                            <div class="sm_switch">
                                                <label :for="`progress-${p}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ progress.name }}</h5>
                                                    <input type="checkbox" :id="`progress-${p}`" :true-value="1" :false-value="0" v-model="progress.archived" hidden>
                                                    <div><span :class="{on: progress.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.sequences"><div class="sm_switch">Sequences</div></li>
                                    <template v-for="(sequence, s) of form.sequences" :key="s">
                                        <li v-if="sequence.archived">
                                            <div class="sm_switch">
                                                <label :for="`sequence-${s}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ sequence.name }}</h5>
                                                    <input type="checkbox" :id="`sequence-${s}`" :true-value="1" :false-value="0" v-model="sequence.archived" hidden>
                                                    <div><span :class="{on: sequence.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.templates"><div class="sm_switch">Templates</div></li>
                                    <template v-for="(template, t) of form.templates" :key="t">
                                        <li v-if="template.archived">
                                            <div class="sm_switch">
                                                <label :for="`template-${t}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ template.name }}</h5>
                                                    <input type="checkbox" :id="`template-${t}`" :true-value="1" :false-value="0" v-model="template.archived" hidden>
                                                    <div><span :class="{on: template.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.journeys"><div class="sm_switch">Journeys</div></li>
                                    <template v-for="(journey, j) of form.journeys" :key="j">
                                        <li v-if="journey.archived">
                                            <div class="sm_switch">
                                                <label :for="`journey-${j}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ journey.name }}</h5>
                                                    <input type="checkbox" :id="`journey-${j}`" :true-value="1" :false-value="0" v-model="journey.archived" hidden>
                                                    <div><span :class="{on: journey.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.pipelines"><div class="sm_switch">Pipelines</div></li>
                                    <template v-for="(pipeline, p) of form.pipelines" :key="p">
                                        <li v-if="pipeline.archived">
                                            <div class="sm_switch">
                                                <label :for="`pipeline-${p}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ pipeline.name }}</h5>
                                                    <input type="checkbox" :id="`pipeline-${p}`" :true-value="1" :false-value="0" v-model="pipeline.archived" hidden>
                                                    <div><span :class="{on: pipeline.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.optins"><div class="sm_switch">Optins</div></li>
                                    <template v-for="(optin, o) of form.optins" :key="o">
                                        <li v-if="optin.archived">
                                            <div class="sm_switch">
                                                <label :for="`optin-${o}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ optin.name }}</h5>
                                                    <input type="checkbox" :id="`optin-${o}`" :true-value="1" :false-value="0" v-model="optin.archived" hidden>
                                                    <div><span :class="{on: optin.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.locations"><div class="sm_switch">Locations</div></li>
                                    <template v-for="(location, l) of form.locations" :key="l">
                                        <li v-if="location.archived">
                                            <div class="sm_switch">
                                                <label :for="`location-${l}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ location.name }}</h5>
                                                    <input type="checkbox" :id="`location-${l}`" :true-value="1" :false-value="0" v-model="location.archived" hidden>
                                                    <div><span :class="{on: location.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                    <li class="archive-title" v-if="archivedAssets.coaches"><div class="sm_switch">Coaches</div></li>
                                    <template v-for="(coach, c) of form.coaches" :key="c">
                                        <li v-if="coach.archived">
                                            <div class="sm_switch">
                                                <label :for="`coach-${c}`" class="switch_option capsule_btn p-0">
                                                    <h5 class="mb-0">{{ coach.name }}</h5>
                                                    <input type="checkbox" :id="`coach-${c}`" :true-value="1" :false-value="0" v-model="coach.archived" hidden>
                                                    <div><span :class="{on: coach.archived}"></span></div>
                                                </label>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step_content" v-if="step == 2 && !faq">
                    <div class="details_info" v-if="consumption.storage > newPlanLimit.storage">
                        <p>You are <span>{{ consumption.storage > newPlanLimit.storage ? consumption.storage - newPlanLimit.storage : 0 }}</span> GB over your file storage limits for the plan you wish to downgrade to. We will adjust your billing by $2 per GB of file storage per month. To downgrade to the {{ newPlan }} plan you will need to delete or pay for <span>{{ consumption.storage > newPlanLimit.storage ? consumption.storage - newPlanLimit.storage : 0 }}</span> GB of data.</p>
                        <div class="perm_box">
                            <label for="keep_storage" class="radio_box">
                                <input type="radio" id="keep_storage" :value="1" v-model="form.form" hidden>
                                <span></span>
                                <h5>I will Keep my file storage at the current rate of $2 per GB/Per month</h5>
                            </label>
                            <label for="delete_storage" class="radio_box">
                                <input type="radio" id="delete_storage" :value="2" v-model="form.form" hidden>
                                <span></span>
                                <h5>I will delete {{ consumption.storage > newPlanLimit.storage ? consumption.storage - newPlanLimit.storage : 0 }} GB's of my file storage and return when I am done.</h5>
                            </label>
                        </div>
                    </div>
                    <div v-else>
                        <p>You are not over your storage limit. Click next to proceed.</p>
                    </div>
                </div>
                <div class="step_content" v-if="step == 2 && faq">
                    <ul class="faq_list">
                        <li v-for="(faq, f) in faqs" :key="f" :class="{'open' : f == 0}">
                            <h5 @click="accordion($event)">{{faq.question}} <i class="fas fa-chevron-down"></i></h5>
                            <div class="answers">
                                <p v-html="faq.answer"></p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="step_content" v-if="step == 3">
                    <div class="terms_info">
                        <p>
                            <!-- I understand I will be billed ${{ plans.new ? plans.new.amount : 0 }} immediately and my deleted assets and contacts will be unavailable.<br/><br/>
                            I will not be able to access, edit, or assign deleted assets to any of my clients. Deleted assets will no longer appear in my menus or my dashboards.<br/><br/>
                            My deleted data may be deleted permanently by Thrive Coach, LLC after 90 days. I also understand that I am only permitted 2 downgrade periods during the life of my account.<br/><br/>
                            After my second downgrade, my plan will only be available for upgrade, hibernation, maintenance of my current plan, or cancellation. -->
                            I understand my new plan limits will take effect immediately. I will be billed for my new plan at the end of my current billing cycle and my deleted assets and contacts will be immediately deleted.<br/><br/>
                            I will not be able to access, edit, or assign deleted assets to any of my clients. Deleted assets will no longer appear in my menus or my dashboards.<br/><br/>
                            My deleted data may be deleted permanently by Thrive Coach, LLC after 90 days. I also understand that I am only permitted 2 downgrade periods during the life of my account.<br/><br/>
                            After my second downgrade, my plan will only be available for upgrade, hibernation, maintenance of my current plan, or cancellation.
                        </p>
                    </div>
                    <div class="perm_box">
                        <label for="agree_terms" class="checkbox">
                            <input type="checkbox" id="agree_terms" :true-value="1" :false-value="0" v-model="form.agree" hidden>
                            <span><i class="fas fa-check"></i></span>
                            <h5>I agree</h5>
                        </label>
                    </div>
                </div>
                <div class="step_content" v-if="step == 4">
                    <h1 class="success_msg">Your account has been successfully downgraded to {{ newPlan }}.</h1>
                </div>
                <div class="action_wpr mb-5">
                    <button type="button" class="btn cancel_btn" @click="step--;" v-if="step > 0 && step < 4">Prev</button>
                    <!-- <button type="button" class="btn save_btn" @click="faq = true;" v-if="step >= 2 && !faq">Agree</button> -->
                    <button type="button" class="btn save_btn" @click="step++; lastStep++;" v-if="step == 0 || step == 1 || step == 2" :class="{ disable: (step > 0 && overdueCount > archivedCount) || (step == 0 && extraConsumption.contacts > 0) }" :disabled="(step > 0 && overdueCount > archivedCount) || (step == 0 && extraConsumption.contacts > 0)">Next</button>
                    <button type="button" class="btn save_btn" @click="handleDowngrade()" v-if="step == 3" :class="{ disable: !form.agree }" :disabled="!form.agree">Finish</button>
                </div>
            </div>
        </div>
        <div class="preview_area show_sidebar" v-if="showAll">
            <form class="product_form">
                <div class="container_wpr">
                    <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
                    <div class="product_container">
                        <div class="content_header popup-title">
                            <h3 class="capitalize">{{ productTitle }}</h3>
                            <h5>{{ overdueAssets[productTitle] && overdueAssets[productTitle] > archiveCount(form[productTitle]) ? overdueAssets[productTitle] - archiveCount(form[productTitle]) : 0 }} {{ productTitle }} need to be deleted</h5>
                            <!-- <div class="approved" v-if="overdueAssets[productTitle] && (overdueAssets[productTitle] - form[productTitle].length == 0)">
                                <img src="@/assets/images/check-anim.gif" alt="">
                            </div> -->
                        </div>
                        <div class="content_body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product Title</th>
                                        <th v-if="!['templates', 'locations', 'coaches'].includes(productTitle)">Member</th>
                                        <th>Active</th>
                                    </tr>
                                </thead>
                                <tbody class="product_list" v-if="allAssets[productTitle]">
                                    <tr v-for="(asset, a) of allAssets[productTitle]" :key="a">
                                        <td>
                                            <div class="product_title">
                                                <img v-if="['locations', 'coaches'].includes(productTitle)" :src="asset.profile_pic" alt="">
                                                <img v-else :src="asset.thumb ? asset.thumb : require(`@/assets/images/thumb/${defaultThumbName(productTitle)}.svg`)" alt="">
                                                <h4 class="mb-0" v-if="['locations', 'coaches'].includes(productTitle)">{{ productTitle == 'locations' ? asset.businessname : asset.full_name }}</h4>
                                                <h4 class="mb-0" v-else>{{ asset.name }}</h4>
                                            </div>
                                        </td>
                                        <td v-if="!['templates', 'locations', 'coaches'].includes(productTitle)">
                                            <h4 class="mb-0">{{ asset.total_assigned }}</h4>
                                        </td>
                                        <td>
                                            <label :for="`${productTitle}-${a}`" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" :id="`${productTitle}-${a}`" :true-value="0" :false-value="1" v-model="form[productTitle][asset.id].archived" @change="handleArchiveUnarchiveAssets(productTitle, form[productTitle][asset.id])" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="action_wpr">
                    <button type="button" class="btn cancel_btn" @click="cancelArchived()">Exit</button>
                    <button type="button" class="btn save_btn" :class="{ disable: overdueAssets[productTitle] > archiveCount(form[productTitle]) }" :disabled="overdueAssets[productTitle] > archiveCount(form[productTitle])" @click="closeModal()">Next</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Account Downgrade',

        data () {
            return {
                step: 0,
                lastStep: 0,
                showAll: false,
                productTitle: '',
                currentPlan: '',
                newPlan: '',
                currentPlanLimit: {
                                      sequences: 0,
                                      playbooks: 0,
                                      templates: 0,
                                      forms: 0,
                                      contacts: 0,
                                      automations: 0,
                                      pages: 0,
                                      locations: 0,
                                      team_members: 0,
                                      pipelines: 0,
                                  },
                newPlanLimit: {
                                  sequences: 0,
                                  playbooks: 0,
                                  templates: 0,
                                  forms: 0,
                                  contacts: 0,
                                  automations: 0,
                                  pages: 0,
                                  locations: 0,
                                  team_members: 0,
                                  pipelines: 0,
                              },
                consumption: {
                                  sequences: 0,
                                  playbooks: 0,
                                  templates: 0,
                                  forms: 0,
                                  contacts: 0,
                                  automations: 0,
                                  pages: 0,
                                  locations: 0,
                                  team_members: 0,
                                  pipelines: 0,
                              },
                plans: [],
                oldInterval: 'month',
                newInterval: 'month',
                form: {
                    storage: 1,
                    agree: 0,
                    playbooks: [],
                    pages: [],
                    forms: [],
                    habits: [],
                    progress: [],
                    optins: [],
                    sequences: [],
                    templates: [],
                    journeys: [],
                    locations: [],
                    coaches: [],
                },
                faq : false,
                assetsInit : false,
                overdueAssets: {},
                archivedAssets: {
                  playbooks: 0,
                  pages: 0,
                  forms: 0,
                  habits: 0,
                  progress: 0,
                  // workouts: 0,
                  sequences: 0,
                  templates: 0,
                  journeys: 0,
                  pipelines: 0,
                  // tasks: 0,
                  optins: 0,
                  locations: 0,
                  coaches: 0,
                },
                assets: [
                    'playbooks',
                    'pages',
                    'forms',
                    'habits',
                    'progress',
                    // 'workouts',
                    'sequences',
                    'templates',
                    'journeys',
                    // 'pipelines',
                    // 'tasks',
                    'optins',
                    'locations',
                    'coaches',
                ],
                faqs:[
                    {
                        question: 'How Do I Unarchive My Contacts and Assets?',
                        answer: 'Your deleted contacts from a higher plan will automatically be recovered when you upgrade back to that plan or a higher plan.'
                    },
                    {
                        question: 'When Will My Assets be Permanently Deleted?',
                        answer: 'Thrive Coach likes to keep an orderly database. For this reason, we typically clean out old accounts every 90 days. We reserve the right to delete your data after your account has been canceled for 90 days or longer.'
                    },
                    {
                        question: 'How Many Times Can I Upgrade And Downgrade?',
                        answer: 'You may upgrade your account as often as you like.<br /> You may downgrade your account 2 times per year if needed. After that, you will only have the option to hibernate, cancel, or maintain your current plan.'
                    },
                    {
                        question: 'How Will I Be Billed For File Storage Over My Limits?',
                        answer: 'We will bill your card on file monthly for any extra contacts, extra locations, Thrive funnels, or extra storage. This will reflect on your credit card statement.'
                    },
                ],
                overdueCount: 0,
                archivedCount: 0,
                extraConsumption: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
            }
        },

        watch: {
            plansAndAddons (plansAndAddons) {
                const vm = this;

                vm.plans = {
                    new: plansAndAddons.plans[vm.newInterval].filter((plan) => plan.nickname == vm.newPlan)[0],
                    old: plansAndAddons.plans[vm.oldInterval].filter((plan) => plan.nickname == vm.currentPlan)[0],
                };
            },

            subscription (subscription) {
                const vm = this;
                const defaultLimit = {
                                    sequences: 0,
                                    playbooks: 0,
                                    templates: 0,
                                    forms: 0,
                                    contacts: 0,
                                    automations: 0,
                                    pages: 0,
                                    locations: 0,
                                    team_members: 0,
                                    pipelines: 0,
                                };

                vm.currentPlanLimit = subscription.extra_consumption[`${vm.currentPlan}_limit`] ? subscription.extra_consumption[`${vm.currentPlan}_limit`] : defaultLimit;
                vm.newPlanLimit     = subscription.extra_consumption[`${vm.newPlan}_limit`] ? subscription.extra_consumption[`${vm.newPlan}_limit`] : defaultLimit;
                vm.consumption      = subscription.extra_consumption.current_consumption ? subscription.extra_consumption.current_consumption : defaultLimit;
                vm.overdueAssets    = JSON.parse(JSON.stringify(subscription.extra_consumption[vm.newPlan]));
                vm.extraConsumption = subscription.extra_consumption[vm.newPlan] ? subscription.extra_consumption[vm.newPlan] : []

                setTimeout(function () {
                    vm.overdueCount = Object.values(vm.overdueAssets).reduce((a, b) => a + b, 0);
                }, 10);

                // delete vm.overdueAssets.locations;
                // delete vm.overdueAssets.coaches;
            },

            allAssets (allAssets) {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(allAssets.form_data));
                vm.form.storage = 1;
                vm.form.agree   = 0;

                setTimeout(function () {
                    vm.assetsInit = true;
                }, 10);
            },

            form: {
                handler (form) {
                    const vm = this;
                    const archivedCountObj = {};

                    Object.keys(form).forEach((type) => {
                        if (!['storage', 'agree'].includes(type)) {
                            vm.archivedAssets[type] = vm.archiveCount(form[type]);

                            archivedCountObj[type] = vm.archivedAssets[type] >= vm.overdueAssets[type] ? vm.overdueAssets[type] : vm.archivedAssets[type];
                        }
                    });

                    vm.archivedCount = Object.values(archivedCountObj).reduce((a, b) => a + b, 0);
                },
                deep: true,
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            allAssets: state => state.billingModule.allAssets,
            plansAndAddons: state => state.billingModule.plans,
            subscription: state => state.billingModule.subscription,
            allAssetsLoader: state => state.billingModule.allAssetsLoader,
        }),

        mounted () {
            const vm    = this;
            const plan  = JSON.parse(atob(vm.$route.params.subscription));

            vm.currentPlan  = plan.op;
            vm.newPlan      = plan.np;
            vm.oldInterval  = plan.oi;
            vm.newInterval  = plan.ni;

            vm.getPlans();
            vm.getSubscription();
            vm.getAllAssets();
        },

        methods: {
            ...mapActions({
                getPlans: 'billingModule/getPlans',
                getAllAssets: 'billingModule/getAllAssets',
                getSubscription: 'billingModule/getSubscription',
                archiveUnarchiveAsset: 'billingModule/archiveUnarchiveAsset',
            }),

            archiveCount (object) {
                return Object.values(object).filter((obj) => obj.archived == 1).length;
            },

            toggleProduct (title, isOverDue = 0) {
                const vm = this;

                if (isOverDue) {
                    vm.productTitle = title;
                    vm.showAll = true;
                }
            },

            accordion (ev) {
                const element     = ev.currentTarget.closest('li');
                const allElements = document.querySelectorAll('.faq_list li');

                if (element.classList.contains('open')) {
                    element.classList.remove('open');
                } else {
                    for (let i = 0; i < allElements.length; i++) {
                        allElements[i].classList.remove('open');
                    }

                    element.classList.add('open');
                }
            },

            closeModal () {
                const vm = this;

                vm.showAll = 0;
            },

            cancelArchived () {
                const vm = this;

                Object.keys(vm.form[vm.productTitle]).forEach((index) => {
                    vm.form[vm.productTitle][index].archived = 0;
                });

                vm.closeModal();
            },

            defaultThumbName (type) {
                const thumbs = {
                    playbooks: 'default-playbook',
                    pages: 'default-page',
                    forms: 'default-form',
                    habits: 'default-habit',
                    progress: 'default-progress',
                    sequences: 'default-sequence',
                    templates: 'default-template',
                    journeys: 'default-journey',
                    optins: 'default-page',
                };

                return thumbs[type];
            },

            handleArchiveUnarchiveAssets (type, asset, index) {
                const vm = this;

                vm.archiveUnarchiveAsset({ type, id: asset.id, archived: asset.archived });
            },

            handleDowngrade () {
                const vm        = this;
                const params    = vm.form;
                const plan      = vm.newPlan;
                const interval  = vm.newInterval;

                const options = Helper.swalConfirmOptions('Are you sure?', `You want to downgraded your subscription to ${plan}`, 'Yes');

                options.preConfirm = () => {
                                          return new Promise(function(resolve, reject) {
                                              axios.post(`${vm.billingApi}/stripe/subscription/${plan}/swap`, { interval }, {
                                                  headers: {
                                                      Accept: 'application/json',
                                                      Authorization: `Bearer ${vm.user.access_token}`,
                                                  },
                                              }).then((resp) => {
                                                  vm.getSubscription();

                                                  Toastr.success(`Subscription has been downgraded successfully!`);
                                                  vm.step++;
                                                  vm.lastStep++;
                                                  resolve(true);
                                              }).catch((err) => {
                                                  Toastr.error(err.response.data.error);
                                                  resolve(true);
                                              });
                                          });
                                      };

                Swal.fire(options);
            },

            handleNextStep (step) {
                const vm = this;

                if (vm.lastStep >= step) {
                    vm.step = step;
                }
            },
        }
    }
</script>

<style scoped>
    .downgrader {
        max-width: 940px;
        width: 100%;
        margin: 0 auto;
    }

    .step_content {
        background: #fff;
        border-radius: 10px;
        padding: 40px;
        border: 1px solid #e9e9e9;
        text-align: left;
        margin: 30px 0;
    }

    .terms_info {
        padding: 30px;
        background: #fbfbfb;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
    }

    .terms_info p {
        font-size: 15px;
        line-height: 23px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .details_info p {
        font-size: 16px;
        line-height: 24px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .details_info p a {
        text-decoration: none;
        color: #2f7eed;
    }

    .plan_details {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0;
    }

    .plan_details li {
        padding: 0 8%;
        position: relative;
    }

    .plan_details li:first-child {
        padding-left: 0;
    }

    .plan_details li:not(:last-child):after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        border-right: 1px solid #e9e9e9;
    }

    .plan_details h5 {
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .plan_details .plan {
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        color: #f2a31d;
        padding: 10px 0 0;
    }

    .assets_wpr {
        display: flex;
        gap: 30px;
        padding: 20px 0;
    }

    .asset_box {
        flex: 1 1 auto;
        border: 1px solid #f1f1f1;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
    }

    .asset_box h4 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .asset_box h4 .success {
        width: 23px;
        height: 23px;
        border: 1px solid #28a745;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #28a745;
        margin-left: auto;
    }

    .asset_list {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .archived_list {
        padding: 10px 0;
        max-height: 715px;
        overflow-y: overlay;
    }

    .archived_list::-webkit-scrollbar {
        width: 3px;
    }

    .archived_list::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #dbdbdb;
    }

    .archived_list .sm_switch {
        padding: 10px 20px;
    }

    .sm_switch .switch_option h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        margin-right: 15px;
    }

    .sm_switch .capsule_btn div {
        flex: 0 0 30px;
        width: 30px;
        height: 16px;
        border-radius: 8px;
        border: 1px solid #C9CBD0;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        background-color: #fff;
    }

    .sm_switch .capsule_btn div span {
        position: absolute;
        left: 2px;
        top: 2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #F2A31D;
        transition: all 0.3s ease-in-out;
        margin: 0;
    }

    .asset_list li .list_item {
        border: 1px solid #f3f3f3;
        border-radius: 20px;
        padding: 8px 20px;
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }

    .asset_list li .list_item.items {
        background: #fbfbfb;
        cursor: pointer;
    }

    .asset_list li button {
        width: 16px;
        height: 16px;
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2c3e50;
        background: #dbdbdb;
        cursor: pointer;
        margin-top: 2px;
    }

    .asset_list li .sucess {
        color: #28a745;
    }

    .asset_list li span {
        margin-left: auto;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
    }

    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }


    .product_form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .product_form .action_wpr {
        margin: 0;
        padding: 20px 30px;
        position: sticky;
        bottom: 0;
        right: 0;
        left: 0;
        background: #FAFAFB;
        border-top: 1px solid #eaeaea;
        z-index: 3;
        margin-top: auto;
    }

    .close_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: absolute;
        left: 20px;
        top: 15px;
        z-index: 6;
        cursor: pointer;
    }

    .container_wpr {
        padding: 40px 15px;
        text-align: left;
    }

    .product_container {
        max-width: 900px;
        margin: 0 auto;
        border-radius: 12px;
        border: 1px solid #e9e9e9;
    }

    .product_container .content_header {
        padding: 22px 30px;
        width: 100%;
        background: #f5f5f5;
        border-radius: 12px 12px 0 0;
        border-bottom: 1px solid #e9e9e9;
        padding-right: 90px;
        position: relative;
    }

    .product_container .content_header h3 {
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        margin: 0 0 8px 0;
    }

    .product_container .content_header h5 {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
        text-align: left;
        margin: 0;
    }

    .product_container .content_header .approved {
        position: absolute;
        right: 25px;
        top: 25px;
    }

    .product_container .content_body table {
        width: 100%;
        background: #fff;
        border-collapse: collapse;
        border-radius: 0 0 12px 12px;
    }

    .product_container .content_body table thead {
        border-bottom: 1px solid #e2e2e2;
    }

    .product_container .content_body table th,
    .product_container .content_body table td {
        text-align: center;
    }

    .product_container .content_body table th:first-child,
    .product_container .content_body table td:first-child {
        text-align: left;
    }

    .product_container .content_body table th:last-child,
    .product_container .content_body table td:last-child {
        text-align: right;
    }

    .product_container .content_body table td .switch_option {
        justify-content: flex-end;
    }

    .product_container .content_body table th {
        padding: 15px 30px;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        border-bottom: 1px solid #e9e9e9;
    }

    .product_container .content_body table tr {
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .product_container .content_body table tr:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    .product_container .content_body table tr:hover {
        box-shadow: 0px 6px 44px rgb(0 0 0 / 5%)
    }

    .product_container .content_body table td {
        padding: 15px 30px;
    }

    .product_container .content_body table td img {
        max-height: 45px;
        width: auto;
        padding-right: 15px;
    }

    .product_container .content_body table td .product_title {
        display: flex;
        align-items: center;
    }

    .product_container .content_body table td h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .product_list li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 30px;
        border-bottom: 1px solid #e9e9e9;
    }

    .product_list li:last-child {
        border: 0;
    }

    .product_list li img {
        max-height: 50px;
        width: auto;
    }

    .product_list li h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
    }

    .disable {
        pointer-events: none;
        opacity: 0.5;
    }

    .perm_box {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
    }

    .perm_box label h5 {
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
    }

    .perm_box label,
    .perm_box label.checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
    }

    .perm_box label.radio_box span {
        width: 16px;
        height: 16px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        display: block;
        position: relative;
        margin: 3px 10px 0 0;
    }

    .perm_box label.radio_box span:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 50%;
        background: #5a5a5a;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .perm_box label.checkbox span {
        margin: 4px 10px 0 0;
    }

    .perm_box label input[type=radio]:checked~span:after {
        transform: scale(1);
    }

    .faq_list {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .faq_list li {
        width: 100%;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
    }

    .faq_list li h5 {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        padding: 20px 50px 20px 30px;
        position: relative;
        cursor: pointer;
    }

    .faq_list li h5 i {
        font-size: 15px;
        color: #5a5a5a;
        position: absolute;
        right: 25px;
        top: 25px;
        transition: all 0.3s ease-in-out;
    }

    .faq_list li .answers {
        padding: 0 30px 25px 30px;
        display: none;
    }

    .faq_list li.open .answers {
        display: block;
    }

    .faq_list li.open h5 i {
        transform: rotate(-180deg);
    }

    .faq_list li .answers p {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .success_msg {
        font-size: 22px;
        line-height: 30px;
        font-weight: 300;
        color: #121525;
        text-align: center;
    }

    .respective_content .quote_wpr {
        display: flex;
        height: 100%;
        justify-content: center;
    }

    .archive-title {
        padding: 15px 20px 0;
    }

    .archive-title .sm_switch {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        border-bottom: 1px dashed #e9e9e9;
        padding: 0;
        padding-bottom: 5px;
    }

    .popup-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 30px !important;
    }
</style>